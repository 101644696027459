import React, { useContext } from "react";
import SummerKindnessSeriesLayout from "@src/layouts/SummerKindnessSeriesLayout";
import SEO from "@src/components/SEO";
import { graphql, useStaticQuery } from "gatsby";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import SummerKindnessModalContent from "@src/components/partials/summer-kindness-series/SummerKindnessModalContent";
import window from "global/window";

function SummerKindnessSeriesWeek6() {
  const data = useStaticQuery(graphql`
    {
      headerImage: file(name: { eq: "week6_web" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 640, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      activity1: file(name: { eq: "play-icon" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      activity2: file(name: { eq: "craftandcreate" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  const { headerImage, activity1, activity2 } = data;
  const modalContext = useContext(ModalContext);
  return (
    <>
      <SEO
        title="The Summer Kindness Series - Week 6"
        description="A simple, weekly series for families to build new habits at home and inspire compassionate kids!"
        image="https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo's Summer Kindness Series - Week 6",
          description:
            "A simple, weekly series for families to build new habits at home and inspire compassionate kids!",
          image: "https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg",
        }}
      />
      <SummerKindnessSeriesLayout
        header="Practice Kindness"
        headerSub="Kindness Habit 6:"
        headerText={
          <>
            Actions speak louder
            <br />
            than words
          </>
        }
        whatisIt="It’s time to throw kindness like (k)onfetti! Spelling aside, it’s often easy to think kind thoughts about others while letting them go unsaid. Yet one act of kindness can often create a domino effect throughout a community. Ready to get this kindness block party started? We sure are!"
        whyIsItImportant="Recognizing kind acts can be hard for young kids who may not know what to look for. So start things off by pointing out the kind acts you see and encourage your kids to follow along. Practicing kindness has been found to increase kids’ happiness, self-esteem, health, and resilience! WOW. There’s no party like a kind party!"
        headerImage={headerImage}
        habbits={[
          {
            header: "Expect kindness:",
            text: "Make it clear that in your home kindness is expected and keep everyone in the family accountable.",
          },
          {
            header: "Generate Possibilities:",
            text: "Kindness must be practiced. To make it easy, create a list of random acts of kindness that everyone in your family can do on a daily basis.",
          },
          {
            header: "Define Kindness:",
            text: "Use the TIP to help your children identify what kindness looks like. (T)ell who received the kindness; (I)dentify the kind act; (P)oint out how the act affected the recipient.",
          },
          {
            header: "Weekly Rituals:",
            text: "Make asking “what kind thing did you do today?” a weekly (or daily!) habit. Or bring a thank you card to a different local hero every week!",
          },
        ]}
        activitiesText="Practice Kindness at home :) These are ideas to inspire new traditions. Did you know when kids are asked to teach it makes ideas more
              memorable?"
        activities={[
          {
            icon: activity1,
            header: "Random Acts of Kindness",
            text: "(or not-so-random)",
            onClick: () => {
              modalContext.showModal(ModalType.SummerKindnessModal, {
                content: (
                  <SummerKindnessModalContent
                    header1="Random Acts of Kindness"
                    text1="Create ripples of kindness in your community! We teamed up with Random Act of Kindness Foundation for a family BINGO card to complete together. Use the kindness cards to spread some goodness and #SeeMojoGo!"
                    text2={
                      <>
                        Activity Type: Family Time
                        <br />
                        Play Time: Kindness has no time limits ;P
                      </>
                    }
                    header2="Practice Kindness"
                    items={[
                      { youtubeUrl: "https://www.youtube.com/embed/YYnWsAoGRPE" },
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/RAK2018-cardsheet.pdf",
                        text: "Random Acts of Kindness Cards",
                        icon: "pdf",
                      },
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/BingoCard.pdf",
                        text: "Kindness Bingo Card",
                        icon: "template",
                      },
                    ]}
                  />
                ),
              });
            },
          },
          {
            icon: activity2,
            header: "Kindness Capsule",
            text: "Collect kind moments",
            onClick: () => {
              window.location.href = "https://static.classdojo.com/img/2018-summer_kindness/kindness_capsule.pdf";
            },
          },
        ]}
      />
    </>
  );
}

export default SummerKindnessSeriesWeek6;
